import React, { useState } from 'react';
import { Box } from '@mui/material';
import { apiMyLead } from '../../../app/constants/urls';
import { sendYandexMetrikaEvent } from '../../../helpers/yandexMetrika';
import FormGenerator from '../FormGenerator';
import { ContactType } from '../../../app/constants/amoCRM';
import { sendMyTargetEvent } from '../../../helpers/myTarget';

const formFields = [
  {
    id: 'name',
    name: 'name',
    label: 'Имя и фамилия',
    type: 'text',
    rules: {
      required: 'Введите имя',
    },
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    type: 'email',
    rules: {
      required: 'Введите адрес почты',
    },
  },
  {
    id: 'phone',
    name: 'phone',
    title: 'Как с вами связаться?',
    type: 'phone',
  },
  // {
  //   id: 'refererName',
  //   name: 'refererName',
  //   label: 'Вы пришли по рекомендации?',
  //   placeholder: 'Напишите от кого (имя, фамилия)',
  //   type: 'slideDown',
  // },
];

export default function ContactsForm({
  setIsSent,
  setIsError,
  extraLeadData,
  buttonLabel = 'отправить',
  ymSubmitEventTypes = [],
  myTargetMetric,
  ymSpecialEventType,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const sendForm = async (data) => {
    try {
      setIsError(false);
      setIsLoading(true);
      await fetch(apiMyLead, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          howToContact: ContactType[data.howToContact],
          ...extraLeadData,
        }),
      });

      if (ymSubmitEventTypes.length) {
        ymSubmitEventTypes.forEach((ymSubmitEventType) => {
          sendYandexMetrikaEvent(ymSubmitEventType);
        });
      }
      if (ymSpecialEventType) {
        sendYandexMetrikaEvent(ymSpecialEventType);
      }
      if (myTargetMetric) {
        sendMyTargetEvent(myTargetMetric);
      }

      setIsSent(true);
    } catch (e) {
      setIsError(true);
      console.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <FormGenerator
        isLoading={isLoading}
        submitHandler={sendForm}
        fields={formFields}
        buttonLabel={buttonLabel}
        wideButton
      />
    </Box>
  );
}
