import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import ContactsModal from '../../shared/Modals/ContactsModal';
import { Section } from '../../shared';
import { EventStatisticModule } from '../../features/EventStatistic/ui/EventStatistic';
import { SubscribeModule } from '../Subscribe/Subscribe';
import { EventMkSubscribeModule } from '../EventMkOnlineSubscribe/ui/EventMkOnlineSubscribe';
import { CanBeInterestModule } from '../CanBeInterest/ui/CanBeInterest';
import SuggestEventCard from '../../shared/SuggestEventCard/SuggestEventCard';
import DodBreaf from '../DodBreaf/DodBreaf';
import DodSpeakers from '../DodSpeakers/DodSpeakers';
import { getProgram } from '../../helpers/lib';
import { getMetrik } from '../../pages-fsd/EventsPage/model/metriks';

const formData = {
  title: 'Запись на мероприятие',
  sentContent: {
    title: 'Вы успешно записались на мероприятие!',
  },
};

export default function DodOnlineSlidingGroup({
  outsideRef,
  eventData,
  data,
  greetingsImgs,
  statistic,
  leadData,
  events,
  setCurrentEvent,
  dodOnlineIntroduceData,
  canBeInterestData,
  commonLeadData,
}) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { city, subTitleEnum, titleEnum, mainSection, eventSpeakers, format } =
    eventData || {};
  const program = getProgram(eventData.titleEnum);
  const ymEvents = getMetrik(program, subTitleEnum, city, 'FlyMod');
  return (
    <Section customStyles={{ paddingTop: 2.5 }}>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <DodBreaf
            online={city === 'ONLINE'}
            isMobile={isSm}
            image={greetingsImgs[`greetings/dod-${format}-breaf-mobile`]}
            description={mainSection}
            data={dodOnlineIntroduceData}
          />
          <Box mt={{ xs: 6.25, lg: 17.5 }}>
            <DodSpeakers speakers={eventSpeakers} />
          </Box>

          <Box mt={{ xs: 6.25, lg: 17.5 }}>
            <EventStatisticModule {...statistic} description="" />
          </Box>
          {!isSm && (
            <Box mt={{ xs: 2, lg: 17.5 }}>
              <Typography variant="desktopH2" color={'kit.text.main'}>
                {data.canBeInterest.title}
              </Typography>

              <Grid container columnSpacing={3} mt={4}>
                <Grid item xs={6} sx={{}}>
                  {events[0] && (
                    <SuggestEventCard
                      {...events[0]}
                      clickHandler={() => setCurrentEvent(events[0])}
                    />
                  )}
                </Grid>
                <Grid item xs={6} sx={{}}>
                  {events[1] && (
                    <SuggestEventCard
                      {...events[1]}
                      clickHandler={() => setCurrentEvent(events[1])}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          {isSm && (
            <Box mt={6.25}>
              <CanBeInterestModule
                leadData={canBeInterestData.leadData}
                title={canBeInterestData.title}
                description={canBeInterestData.description}
                events={events}
                setCurrentEvent={setCurrentEvent}
              />
            </Box>
          )}
          {!isSm && (
            <Box mt={{ xs: 2, lg: 17.5 }}>
              <SubscribeModule
                leadData={leadData.subscribe}
                ymSubmitEventType={`event_emailEvents_${eventData.urlSlug}`}
              />
            </Box>
          )}
        </Grid>
        {!isSm && (
          <Grid item xs={12} lg={4} sx={{ position: 'relative', ml: 'auto' }}>
            <Box
              sx={{
                ml: 'auto',
                position: 'sticky',
                top: 68,
                backgroundColor: 'kit.background.gray',
                p: 4,
                mx: 1,
                borderRadius: 4,
              }}
            >
              <ContactsModal
                leadData={{
                  ...commonLeadData,
                  titleEnum,
                  subTitleEnum,
                }}
                formData={formData}
                buttonLabel="Записаться"
                ymSubmitEventTypes={[ymEvents]}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Section>
  );
}
